<template>
  <div id="bank-create-details-tab" @keyup.ctrl.enter="bank_update_data">
    <div class="vx-col w-full">
      <div class="vx-row">
        <div class="vx-col w-full mb-6">
          <vs-input v-model="dataLocal.bank_id" class="w-full" label-placeholder="Bank ID" readonly />
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-6">
          <vs-input v-model="dataLocal.bank_name" class="w-full" label-placeholder="Bank Name" />
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-6">
          <vs-select class="w-full mt-5" placeholder="Bank Status" v-model="dataLocal.bank_status">
            <vs-select-item :key="index" :value="option" :text="option | capitalize" v-for="(option, index) in statusOptions" />
          </vs-select>
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full">
          <vs-input class="w-full" label-placeholder="Thumbnail URL" v-model="dataLocal.bank_thumbnail" />
        </div>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-full mt-2">
          <vs-textarea
            v-model="dataLocal.bank_description"
            :counter-danger.sync="bank_description_counter"
            counter="400"
            height="100px"
            label="Bank Description"
          />
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-button class="mb-2 mr-2" color="success" type="filled" @click="bank_update_data">Update Bank </vs-button>
          <vs-button class="mb-2 ml-2" color="warning" type="border" @click="reset_data">Reset </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      bank_description_counter: null,

      statusOptions: ['active', 'hidden', 'disabled'],
    };
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    bank_update_data() {
      this.$emit('update_bankData', this.dataLocal);
    },
    reset_data() {
      this.dataLocal = JSON.parse(JSON.stringify(this.data));
    },
  },
};
</script>

<style scoped></style>
