<!-- =========================================================================================
  File Name: EditBank.vue
  Description: User Edit Page

========================================================================================== -->

<template>
  <div id="page-user-edit">
    <vs-alert color="danger" title="Bank Not Found" :active.sync="errorOccurred">
      <span>Bank record with id: {{ $route.params.bankId }} not found. </span>
      <span>
        <span>Check </span><router-link :to="{ name: 'admin-list-examedge-bank' }" class="text-inherit underline">All Banks</router-link>
      </span>
    </vs-alert>

    <vx-card v-if="bankData">
      <div slot="no-body" class="tabs-container px-6 pt-6">
        <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
          <vs-tab label="Details" icon-pack="feather" icon="icon-edit">
            <div class="tab-text">
              <bank-details-tab class="mt-4" :data="bankData" v-on:update_bankData="update_bankData" />
            </div>
          </vs-tab>
          <!---    <vs-tab label="Information" icon-pack="feather" icon="icon-info">
            <div class="tab-text">
          <user-edit-tab-information class="mt-4" :data="user_data" />
            </div>
          </vs-tab> --->
          <!---  <vs-tab label="Social" icon-pack="feather" icon="icon-share-2">
          <div class="tab-text">
          <user-edit-tab-social class="mt-4" :data="user_data" />
            </div>
          </vs-tab> --->
        </vs-tabs>
      </div>
    </vx-card>
  </div>
</template>

<script>
import BankDetailsTab from './BankDetailsTab.vue'

export default {
  components: {
    BankDetailsTab
  },
  data () {
    return {
      bankData: null,
      errorOccurred: false,

      activeTab: 0
    }
  },
  methods: {
    loadBankData () {
      this.$http
        .get(`testprep/banks/${this.$route.params.bankId}`)
        .then((response) => {
          if (response.status === 200) {
            this.bankData = {}
            this.bankData.bank_code = response.data.data.code
            this.bankData.bank_id = response.data.data.id
            this.bankData.bank_name = response.data.data.name
            this.bankData.bank_description = response.data.data.description
            this.bankData.bank_status = response.data.data.status
            this.bankData.bank_thumbnail = response.data.data.thumbnail
          }
        })
        .catch((error) => {
          this.errorOccurred = true
          this.error_msg = error
        })
    },
    update_bankData (data) {
      const pl = {
        name: data.bank_name,
        code: data.bank_code,
        description: data.bank_description,
        thumbnail: data.bank_thumbnail,
        status: data.bank_status === undefined ? 'active' : data.bank_status
      }

      if (pl.name === undefined) {
        return this.$vs.notify({
          title: 'Failed to update bank',
          text: 'Please provide a Bank Name',
          color: 'danger',
          position: 'top-right'
        })
      }

      this.$vs.loading()

      this.$http
        .post(`testprep/banks/${this.$route.params.bankId}`, pl)
        .then((response) => {
          if (response.status === 200) {
            this.$vs.loading.close()
            this.$vs.notify({
              title: 'Updated Bank Successfully',
              text: `Bank ${pl.name} has been updated.`,
              color: 'success',
              position: 'top-right'
            })
            return this.loadBankData()
          }
        })
        .catch((exception) => {
          this.$vs.loading.close()

          let error = 'An unknown error occurred while updating this bank'
          if (exception.response) {
            error = exception.response.data.error.description
          }

          return this.$vs.notify({
            title: 'Failed to update bank',
            text: error,
            color: 'danger',
            position: 'top-right'
          })
        })
    }
  },
  created () {
    this.loadBankData(this.$route.params.bankId)
  }
}
</script>
